import { formatValue } from "react-currency-input-field";
import { GridValueFormatterParams } from "@mui/x-data-grid";

export function convertStringToCurrency(number: string) {
    if (number && number.length === 0) return null;

    return formatValue({
        value: number.toString(),
        groupSeparator: ",",
        decimalSeparator: ".",
        prefix: "$",
    });
}

export function convertNumberToCurrency(number: number) {
    return formatValue({
        value: (number / 100).toFixed(2).toString(),
        groupSeparator: ",",
        decimalSeparator: ".",
        prefix: "$",
    });
}

export function convertCurrencyToNumber(value: string) {
    return (Number(value.replace(/[^0-9.-]+/g, "")) * 100) as number;
}

export const taxPercentageFormatter = (value: string) => {
    return `${Number(value).toFixed(2).toString()}%`;
};

export const currencyValueFormatter = (params: GridValueFormatterParams<number>) => {
    if (params.value == null) {
        return "";
    }
    return convertStringToCurrency((params.value / 100)?.toFixed(2).toString());
};

export const decimalValueFormatter = (params: GridValueFormatterParams<number>) => {
    if (params.value == null && params.value === 0) {
        return "";
    }

    return formatValue({
        value: params.value.toFixed(2).toString(),
        groupSeparator: ",",
        decimalSeparator: ".",
    });
};

export const getNextSunday = () => {
    const currentDate = new Date();
    const nextSunday = new Date(currentDate);
    nextSunday.setDate(currentDate.getDate() - currentDate.getDay() + 7);
    return nextSunday.toISOString();
};

export const getEndDate = (weeks: number) => {
    const currentDate = new Date();
    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + weeks * 7);
    return endDate.toISOString();
};

export const formatDisplayDate = (params: GridValueFormatterParams<string>) => {
    if (params.value == null) {
        return "";
    }
    return params.value.split("T")[0];
};
