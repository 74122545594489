import classes from "../../form/components/common/Common.module.scss";
import React from "react";
import { DelinquentCustomer } from "../../../models/DelinquentCustomer";
import { convertNumberToCurrency } from "../../../utils/CommonUtils";

const DelinquentsSummary = ({ customers, refreshData }) => {
    const getTotalAmountDue = (delinquentCustomers: DelinquentCustomer[]) => {
        if (delinquentCustomers?.length === 0) {
            return -1;
        }

        return delinquentCustomers.map((customer) => customer.amountDue).reduce((sum, amountDue) => sum + amountDue);
    };

    const getAverageDefaultScore = (delinquentCustomers: DelinquentCustomer[]) => {
        if (delinquentCustomers?.length === 0) {
            return -1;
        }

        return (
            delinquentCustomers.map((customer) => customer.defaultScore).reduce((sum, amountDue) => sum + amountDue) /
            delinquentCustomers.length
        );
    };

    return (
        <div className={classes.fieldSetContainer}>
            <fieldset className={classes.fieldSet}>
                <legend className={classes.legend}> Delinquency Tracker Summary</legend>
                <div className={classes.fieldSetColumns}>
                    <div className={classes.div}>
                        <label className={classes.label}>Customer Count:</label>
                        <label className={classes.labelBoldText}>{customers?.length}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Total Amount Due:</label>
                        <label className={classes.labelBoldText}>{convertNumberToCurrency(getTotalAmountDue(customers))}</label>
                    </div>
                    <div className={classes.div}>
                        <label className={classes.label}>Average Default Score:</label>
                        <label className={classes.labelBoldText}>{getAverageDefaultScore(customers).toFixed(2)}</label>
                    </div>
                    <div className={classes.div} onClick={refreshData}>
                        <img src={"/assets/Refresh.svg"} alt="Refresh Icon" className={classes.refreshButton}></img>
                    </div>
                </div>
            </fieldset>
        </div>
    );
};

export default DelinquentsSummary;
