import React from "react";
import { Grid } from "@material-ui/core";
import { useAppDispatch } from "../../redux/hooks";
import SwapVehicleModal from "./modals/SwapVehicleModal";
import { MODAL_TYPE, setModalType } from "../../redux/modalSlice";
import classes from "./Reservations.module.scss";

import CreditReportModal from "./modals/CreditReportModal";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "../../shared/components/ButtonPrimary";

function Reservations() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    return (
        <div className={classes.container}>
            <Grid className={classes.grid}>
                <ButtonPrimary
                    onClick={() => navigate("/admin/billing-service")}
                    text={"Billing Service"}
                    testId={"billing-service-button"}
                />
                <ButtonPrimary onClick={() => navigate("/admin/delinquents")} text={"Delinquency Tracker"} testId={"delinquents-button"} />
                <ButtonPrimary
                    onClick={() => dispatch(setModalType(MODAL_TYPE.CREDIT_REPORT))}
                    text={"Credit Report"}
                    testId={"export-button"}
                />
                <ButtonPrimary
                    onClick={() => dispatch(setModalType(MODAL_TYPE.SWAP_VEHICLE))}
                    text={"Vehicle Swap"}
                    testId={"swap-button"}
                ></ButtonPrimary>
                <ButtonPrimary
                    onClick={() => navigate("/admin/credit-report")}
                    text={"All Credit Report"}
                    testId={"all-credit-button"}
                    hide={true}
                />
            </Grid>
            <CreditReportModal />
            <SwapVehicleModal />
        </div>
    );
}

export default Reservations;
